exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-features-image-occlusion-tsx": () => import("./../../../src/pages/features/image-occlusion.tsx" /* webpackChunkName: "component---src-pages-features-image-occlusion-tsx" */),
  "component---src-pages-features-import-tsx": () => import("./../../../src/pages/features/import.tsx" /* webpackChunkName: "component---src-pages-features-import-tsx" */),
  "component---src-pages-features-spaced-repetition-tsx": () => import("./../../../src/pages/features/spaced-repetition.tsx" /* webpackChunkName: "component---src-pages-features-spaced-repetition-tsx" */),
  "component---src-pages-features-special-formatting-tsx": () => import("./../../../src/pages/features/special-formatting.tsx" /* webpackChunkName: "component---src-pages-features-special-formatting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

